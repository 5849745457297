import React from 'react';
import { useMedia } from 'react-use';
import { useTreatments } from '@splitsoftware/splitio-react';
import cx from 'classnames';
import { Button } from '@hagerty/react-components';

import ActionLink from 'src/components/checkout/ActionLink';
import { isAgent } from 'src/api/client';

import { Divider } from 'src/components/atom';
import { Translate } from 'src/components/Translate';
import { Mileage, Money } from 'src/models/types';
import { useShelbyCustomer } from 'src/hooks/useShelbyCustomer';
import { Split, Treatment } from 'src/constants/Split';

import styles from './CTA.module.scss';

type VisibleProps = {
  show: boolean;
};

type DetailsProps = {
  // Membership base price
  basePrice: Money;

  // Current selected mileage
  mileage: Mileage;
};

type ContinueProps = {
  // Handler for Continue button clicked.
  onContinue: () => void;
};

type ResetProps = {
  // Handler rest to level 1
  onReset: () => void;
};

type PriceProps = {
  // The price object
  price: Money;

  // The extra props
  [x: string]: any;
};

const Price: React.FC<PriceProps> = ({ price, ...x }) => {
  return <Translate {...x} resourceKey={`currency.format.${price.currency}`} values={price} />;
};
const Title: React.FC = () => {
  const treatments = useTreatments([Split.ExperimentMonthlyPricing]);
  const isMonthlyPrice = treatments[Split.ExperimentMonthlyPricing].treatment === Treatment.On && !isAgent;

  return (
    <Translate
      as="h3"
      className={styles.title}
      resourceKey={isMonthlyPrice ? 'checkout.lead.monthlyPrice.membership' : 'checkout.lead.drivers.club'}
    />
  );
};

type ToggleProps = {
  value: boolean;
  onChange: (value: boolean) => void;
  className: string;
};

const Toggle: React.FC<ToggleProps> = ({ value, onChange }) => {
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    onChange(!value);
  };

  const label = value ? 'Hide details' : 'Show details';
  const icon = value ? 'hide' : 'show';

  return <ActionLink icon={icon} label={label} onClick={onClick} data-cy="detailsToggle" />;
};

const Details: React.FC<DetailsProps & VisibleProps & ResetProps> = ({ basePrice, mileage, show, onReset }) => {
  const small = useMedia('(min-width: 768px) and (max-width: 1023px)');
  return (
    <div
      className={cx(styles.details, show && styles.show, mileage.level > 1 && styles.removable)}
      data-cy="summaryDetails"
    >
      <div className={styles.row}>
        <Translate className={styles.label} data-cy="item.membership" resourceKey="checkout.cta.item.membership" />
        <Price data-cy="basePrice" price={basePrice} />
      </div>
      <div className={styles.row}>
        <Translate className={styles.label} data-cy="item.roadside" resourceKey="checkout.cta.item.roadside" />
        <Price data-cy="roadsidePrice" price={mileage?.price} />
      </div>
      <Translate
        as="div"
        className={cx(styles.row, styles.small, styles.extra)}
        data-cy="roadside.miles"
        resourceKey={'checkout.mileage.miles'}
        values={mileage}
      />
      <Translate
        as="div"
        className={cx(styles.row, styles.small, styles.extra)}
        data-cy="roadside.vehicles"
        resourceKey={[`checkout.mileage.vehicles.${mileage.vehiclesCovered}`, 'checkout.mileage.vehicles.fallback']}
      />
      {mileage.level > 1 && (
        <div className={cx(styles.row, styles.remove)}>
          <ActionLink icon="clear" label="Remove added miles" onClick={onReset} data-cy="removeMiles" />
        </div>
      )}
      <Divider margin={small ? 16 : 20} />
    </div>
  );
};

type SmallProps = {
  small: boolean;
};

type SummaryProps = {
  total: Money;
};
const Summary: React.FC<SummaryProps> = ({ total }) => {
  const treatments = useTreatments([Split.ExperimentMonthlyPricing]);
  const isMonthlyPrice = treatments[Split.ExperimentMonthlyPricing].treatment === Treatment.On && !isAgent;
  const isWide = useMedia('(min-width: 768px)');

  return (
    <div className={styles.summary}>
      <div className={styles.labels}>
        <div className={styles.total} data-cy="summary.labels.total">
          {isWide ? (
            isMonthlyPrice ? (
              <div className={styles.price} data-cy="totalPrice">
                <Translate resourceKey="checkout.cta.price" />
                <span className={styles.month}>/month</span>
              </div>
            ) : (
              <Translate resourceKey={'checkout.cta.total'} />
            )
          ) : (
            <Translate resourceKey={isMonthlyPrice ? 'checkout.lead.monthlyPrice.membership' : 'checkout.cta.total'} />
          )}
        </div>
        <div className={styles.billed} data-cy="summary.labels.period">
          <Translate resourceKey="checkout.cta.billed" />
        </div>
      </div>
      {isMonthlyPrice ? (
        !isWide && (
          <div className={styles.price} data-cy="totalPrice">
            <Translate resourceKey="checkout.cta.price" />
            <span className={styles.month}>/month</span>
          </div>
        )
      ) : (
        <Price className={styles.price} as="div" data-cy="totalPrice" price={total} />
      )}
    </div>
  );
};

const ContinueButton: React.FC<ContinueProps & SmallProps> = ({ small, onContinue }) => {
  const treatments = useTreatments([Split.ExperimentMonthlyPricing]);
  const isMonthlyPrice = treatments[Split.ExperimentMonthlyPricing].treatment === Treatment.On && !isAgent;
  return (
    <>
      <Button className="button button_primary button_primary_large full-width" onClick={onContinue} testId="continue">
        <Translate resourceKey={isMonthlyPrice ? 'checkout.CTA.text.continue' : 'checkout.CTA.text.get-membership'} />
        {!small && (
          <svg className="button_primary__icon icon" aria-hidden="true">
            <use xlinkHref="#16-arrow-right" />
          </svg>
        )}
      </Button>
      {isMonthlyPrice && (
        <div className={styles.underline} data-cy="summary.labels.underline">
          <Translate as="div" resourceKey={'checkout.cancel.underline'} />
        </div>
      )}
    </>
  );
};

// total price and checkout button.
const CTA: React.FC<DetailsProps & ContinueProps & ResetProps> = ({ basePrice, mileage, onContinue }) => {
  const small = useMedia('(min-width: 768px) and (max-width: 1023px)');
  const { isStfrmCustomer, hasStfCustomerStandalonePurchase } = useShelbyCustomer();

  if (!mileage) {
    return null;
  }

  const totalPrice: Money = { ...basePrice, amount: basePrice.amount + mileage.price.amount };

  return (
    <div className={cx(styles.cta, styles.singlePricingTier)} data-cy="cta">
      <Title />
      <Divider margin={small ? 16 : 20} />
      <Summary total={totalPrice} />
      {(!isStfrmCustomer || hasStfCustomerStandalonePurchase) && (
        <ContinueButton small={small} onContinue={onContinue} />
      )}
    </div>
  );
};

export default CTA;
