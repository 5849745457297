import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'src/components/Translate';

export default function TransactionDetailRow({ resourceKey, values }) {
  return (
    <span className="transaction-detail-row">
      <Translate resourceKey={resourceKey} values={values} />
    </span>
  );
}

TransactionDetailRow.propTypes = {
  resourceKey: PropTypes.string.isRequired,
  values: PropTypes.object,
};
