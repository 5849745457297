import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type Span = {
  text: string;
  action?: boolean;
};

export type Benefit = {
  id: number;

  title: string;
  // the SVG icon
  icon: string;

  // The description of the benefit
  description: Span[];
};

const parseSpan = (text: string): Span[] => {
  const spans: Span[] = [];
  const m = text.match(/^(.*)\[([^\]]*)]\(\)(.*)$/);
  if (!m) {
    spans.push({ text });
  } else {
    m[1].length > 0 && spans.push({ text: m[1] });
    m[2].length > 0 && spans.push({ text: m[2], action: true });
    m[3].length > 0 && spans.push({ text: m[3] });
  }
  return spans;
};

export const parseBenefits = (markdown: string) => {
  const lines = markdown
    .match(/[^\r\n]+/g)
    .map((line) => line.trim())
    .filter((line) => line.length > 0);
  const benefits: Benefit[] = [];
  const titlePrefix = '#### ';
  const svgBeg = '<svg';
  const svgEnd = '</svg>';
  for (let i = 0; i < lines.length; ++i) {
    let line = lines[i];
    if (line === undefined) {
      break;
    }
    if (line.startsWith(titlePrefix)) {
      benefits.push({
        id: benefits.length,
        title: line.substring(titlePrefix.length).trimLeft(),
        icon: null,
        description: [],
      });
      continue;
    }

    // Skip the initial comments
    if (benefits.length === 0) {
      continue;
    }

    if (line.startsWith(svgBeg)) {
      const iconLines = [line];
      while (!line.endsWith(svgEnd)) {
        ++i;
        line = lines[i];
        iconLines.push(line);
      }
      benefits[benefits.length - 1].icon = `data:image/svg+xml;utf8,${encodeURIComponent(iconLines.join(''))}`;
      continue;
    }
    benefits[benefits.length - 1].description = parseSpan(line);
  }
  return benefits;
};

const useBenefits = (): Benefit[] => {
  const { t } = useTranslation();

  return useMemo(() => {
    return parseBenefits(t('checkout.benefits.list.fullSingleTier'));
  }, []);
};

export default useBenefits;
