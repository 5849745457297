import React from 'react';
import PropTypes from 'prop-types';

export default function Expander({ expanded, toggleExpand }) {
  return (
    <div>
      <div className="expander-icon" onClick={toggleExpand}>
        <svg className="icon" transform={expanded ? 'rotate(180)' : ''}>
          <use xlinkHref="#16-small-triangle-down"></use>
        </svg>
      </div>
      <div className="divider" />
    </div>
  );
}

Expander.propTypes = {
  expanded: PropTypes.bool.isRequired,
  toggleExpand: PropTypes.func.isRequired,
};
