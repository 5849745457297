import React from 'react';
import PropTypes from 'prop-types';

import {
  AmexCard,
  DefaultCard,
  DiscoverCard,
  DinersCard,
  JCBCard,
  MasterCard,
  UnionPayCard,
  VisaCard,
} from 'src/components/atom/icons';

export default function CardBrand({ brand }) {
  return (() => {
    switch (brand) {
      case 'visa':
        return <VisaCard />;
      case 'mastercard':
        return <MasterCard />;
      case 'amex':
        return <AmexCard />;
      case 'discover':
        return <DiscoverCard />;
      case 'diners':
        return <DinersCard />;
      case 'jcb':
        return <JCBCard />;
      case 'unionpay':
        return <UnionPayCard />;
      default:
        return <DefaultCard />;
    }
  })();
}

CardBrand.propTypes = {
  brand: PropTypes.string,
};
