import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'src/components/Translate';

export default function PlanLabel({ label, color = 'blue' }) {
  return (
    <span className={`card-label card-label--${color} plan-label`}>
      <Translate resourceKey={label} />
    </span>
  );
}

PlanLabel.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['blue', 'yellow']),
};
