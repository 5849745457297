import React, { useCallback, useEffect, useState } from 'react';
import { navigate, PageProps } from 'gatsby';
import useSelectedLevel from 'src/hooks/useSelectedLevel';
import styles from './index.module.scss';
import { isAgent } from 'src/api/client';

import { trackEvent, trackPageLoad } from 'src/services/tracker';
import LoadingContainer from 'src/components/LoadingContainer';
import useActiveProduct from 'src/api/useActiveProduct';
import logTrackedEvent from 'src/helpers/logTrackedEvent';
import usePreventMemberCheckout from 'src/hooks/usePreventMemberCheckout';
import { editPurchaseFlowRedirect } from 'src/helpers/purchaseFlowRedirect';

import Benefits from 'src/components/checkout/Benefits';
import Banner from 'src/components/molecule/Banner';
import CTA from 'src/components/checkout/CTA';
import FAQ from 'src/components/checkout/FAQ';
import { useUserTracker } from 'src/hooks/useUserTracker';
import { useAutoLogin } from 'src/hooks/useAutoLogin';
import { useShelbyCustomer } from 'src/hooks/useShelbyCustomer';
import { useFreeTrial } from 'src/hooks/useFreeTrial';

import { useTreatments } from '@splitsoftware/splitio-react';
import { isReady } from 'src/components/SplitContext/isReady';
import { Split, Treatment } from 'src/constants/Split';
import { PlanType } from 'src/constants/Tealium';

export default function CheckoutIndexPage({ location }: PageProps) {
  const { isEligibleForFreeTrial } = useFreeTrial();
  const splitIsReady = isReady();
  const treatments = useTreatments([
    Split.ExperimentMonthlyPricing,
    Split.FeaturePurchaseFlowOptimization,
    Split.FeatureCreateAccountCheckoutFlow,
  ]);
  const hasMonthlyPriceExperiment = treatments[Split.ExperimentMonthlyPricing].treatment === Treatment.On;
  const hasPurchaseOptimizationFeature = treatments[Split.FeaturePurchaseFlowOptimization].treatment === Treatment.On;
  const hasCreateAccountCheckoutFlow = treatments[Split.FeatureCreateAccountCheckoutFlow].treatment === Treatment.On;
  const initialLevel = isEligibleForFreeTrial && !isAgent ? 4 : 2;

  const { loading, product } = useActiveProduct();
  const [level, setLevel] = useSelectedLevel(initialLevel);
  const [hasLoaded, setHasLoaded] = useState(false);
  const { banner, setBanner } = useShelbyCustomer();
  const user = useUserTracker();
  useAutoLogin();

  if (isAgent) {
    navigate(`/checkout/information/${location.search}`);
  } else {
    usePreventMemberCheckout();
  }

  if (hasCreateAccountCheckoutFlow || isEligibleForFreeTrial || hasPurchaseOptimizationFeature) {
    navigate(`/checkout/information/${location.search}`);
  }

  const mileages = product?.mileages || [];
  const plan = mileages && mileages[level - 1];

  const handleRemove = () => {
    setLevel(initialLevel);
  };

  const handleContinue = useCallback(() => {
    logTrackedEvent(trackEvent, {
      _tag_name: 'get_membership',
      ...user,
      product_code: plan.name,
      commerce_product: [plan.name],
      plan_type: PlanType.New,
    });

    return navigate(`/checkout/information/${location.search}`);
  }, [mileages, level, user]);

  // Pageload view
  useEffect(() => {
    if (user && splitIsReady && !hasLoaded && mileages.length) {
      const variant = hasMonthlyPriceExperiment ? Treatment.On : Treatment.Off;
      const testExperience = `${Split.ExperimentMonthlyPricing}_${variant}`;
      trackPageLoad({
        _tag_name: 'checkout_view',
        ...user,
        commerce_product: [plan.name],
        test_experience: testExperience,
      });
      setHasLoaded(true);
    }
  }, [user, splitIsReady, hasLoaded, mileages]);

  // Re-fire view when mileage level is updated
  // Remove this when removing full single pricing tier flag
  useEffect(() => {
    if (user && hasLoaded) {
      trackPageLoad({
        _tag_name: 'checkout_view',
        ...user,
        commerce_product: [plan.name],
        plan_type: PlanType.New,
      });
    }
  }, [level]);

  // Initialize selected sku with level 1 or requested level when mileages are loaded.
  useEffect(() => {
    setLevel(location.state?.['level'] || initialLevel);
  }, [splitIsReady]);

  // Save the checkout redirect URL
  useEffect(() => {
    editPurchaseFlowRedirect(location.search);
  }, [location.search]);

  if (loading || !product) {
    return <LoadingContainer height={500} />;
  }

  return (
    <>
      {banner?.display && <Banner banner={banner} setBanner={setBanner} noTimeout />}
      <div className={styles.container}>
        <div className={styles.main}>
          <Benefits />
          <FAQ />
        </div>
        <div className={styles.side} data-cy="CTA">
          <CTA
            basePrice={product?.basePrice}
            mileage={mileages[level - 1]}
            onReset={handleRemove}
            onContinue={handleContinue}
          />
        </div>
      </div>
    </>
  );
}
