import React from 'react';
import useFAQs from './useFAQs';
import { Accordion } from 'src/components/designSystems/Accordion';
import { Translate } from 'src/components/Translate';
import { trackEvent } from 'src/services/tracker';
import logTrackedEvent from 'src/helpers/logTrackedEvent';
import { useUserTracker } from 'src/hooks/useUserTracker';

import styles from './FAQ.module.scss';

const Title: React.FC = () => (
  <Translate as="div" className={styles.title} resourceKey="checkout.faq.title" data-cy="faq-title" />
);

const FAQ: React.FC = () => {
  const user = useUserTracker();

  const handleClick = (text: string) => {
    logTrackedEvent(trackEvent, {
      _tag_name: 'page_interaction',
      ...user,
      action: 'click',
      action_name: text,
    });
  };

  const items = useFAQs();
  return (
    <div data-cy="faq">
      <Title />
      <div className="accordion" data-toggle="accordion" aria-label="Accordion Control Group Buttons">
        <div className="accordion__tabs" data-cy="faq-list">
          {items.map(({ q, a }, i) => (
            <Accordion summary={q} details={a} id={`faq-${i}`} key={`faq-${i}`} onClick={handleClick} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
