import React from 'react';
import styles from './Divider.module.scss';

type DividerProps = {
  // Stroke width of the divider in px.
  size?: number;
  // Margin for margin above and blow the divider.
  margin: number;
};
export const Divider: React.FC<DividerProps> = ({ margin, size = 1 }) => (
  <div
    className={styles.divider}
    style={{
      marginTop: margin,
      marginBottom: margin,
      height: size,
    }}
  />
);
