import { navigate } from "gatsby";
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'src/components/Translate';

export default function AutoRenewButton({ willBeCanceled, backUrl }) {
  const autoRenew = {
    button: 'membership.autoRenew.button',
  };

  const gotoRenewConfirm = useCallback(() => {
    navigate('/my-account/auto-renew/confirm/', { state: { origin: backUrl } });
  }, [backUrl]);

  if (!willBeCanceled) {
    return null;
  }

  return (
    <button className="button button_secondary" data-cy="auto-renew" onClick={gotoRenewConfirm}>
      <Translate resourceKey={autoRenew.button} />
    </button>
  );
}

AutoRenewButton.propTypes = {
  willBeCanceled: PropTypes.bool,
  backUrl: PropTypes.string,
};
