import React from 'react';
import { useTranslation } from 'react-i18next';
import { isAgent } from 'src/api/client';

import useBenefits, { Benefit } from 'src/components/checkout/useBenefits';

import styles from './Benefits.module.scss';

const Title: React.FC = () => {
  const { t } = useTranslation();
  return (
    <h1 className={styles.heading} data-cy="hading">
      {isAgent ? t('checkout.main.agent.headline') : t('checkout.main.headline')}
    </h1>
  );
};

const BenefitBox: React.FC<Benefit> = ({ id, title, icon, description }) => (
  <li className={styles.benefit} data-cy={`benefit-${id}`}>
    <img className={styles.icon} src={icon} alt="benefit-icon" data-cy="icon" />
    <div>
      <div className={styles.title} data-cy="title">
        {title}
      </div>
      <div className={styles.brief} data-cy="description">
        {description.map(({ text }, index) => (
          <span key={index}>{text}</span>
        ))}
      </div>
    </div>
  </li>
);

const BenefitsGrid: React.FC = () => {
  const benefits = useBenefits();
  return (
    <ul className={styles.benefits}>
      {benefits.map((benefit) => (
        <BenefitBox key={benefit.id} {...benefit} />
      ))}
    </ul>
  );
};

const Benefits: React.FC = () => (
  <div className={styles.benefitBoxes}>
    <Title />
    <BenefitsGrid />
  </div>
);

export default Benefits;
